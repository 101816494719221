<template>
  <ul class="gaia-link">
    <li v-for="(link, index) in gaiaLinks" :key="index">
      <a v-if="link != null && link.url != null" :href="link.url" target="_blank">{{
        link.label
      }}</a>
    </li>
  </ul>
</template>
<script>
import TableRendererMixin from 'piivo-ui/src/components/table/renderers/TableRendererMixin';

export default {
  mixins: [TableRendererMixin],
  computed: {
    /**
     * Gets gaia links object (parse value if it's a string, nothing otherwise).
     */
    gaiaLinks() {
      return this.value && typeof this.value === 'string' ? JSON.parse(this.value) : this.value;
    },
  },
};
</script>
