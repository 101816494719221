// Script d'injection spécifique Leroy Merlin
import './static/style/_all.scss';

import LmGaiaLinksRenderer from './renderers/LmGaiaLinksRenderer';

export function init() {
  if (
    window &&
    window.Piivo &&
    window.Piivo.poster &&
    window.Piivo.poster.tableRenderers &&
    window.Piivo.poster.tableRenderers.registerRenderer
  ) {
    window.Piivo.poster.tableRenderers.registerRenderer('LmGaiaLinks', LmGaiaLinksRenderer);
  }
}

init();
